import React from 'react'
import { Container } from 'react-bootstrap/Container'
import { Row } from 'react-bootstrap/Row'
import { Col } from 'react-bootstrap/Col'
import Carousel from 'react-bootstrap/Carousel';
import veer1 from '../assets/veer1.png'
import veer2 from '../assets/veer2.png'
import veer3 from '../assets/veer3.png'
import veer4 from '../assets/veer4.png'
import img1 from '../assets/image1.jpeg'
import img2 from '../assets/image2.jpeg'
import img3 from '../assets/image3.jpeg'
import b2 from '../assets/b2.png'
import b16 from '../assets/b16.png'
import b17 from '../assets/b17.png'
import b18 from '../assets/b18.png'
import i23 from '../assets/i23.jpg'

function Home() {
  return (
    <>
     <div className="px-0 container-fluid">
      <div className="mx-0 row">
        <div className="w-100 px-0 col-sm-12">
          <div className="tickerwrap d-flex">
            <div className="tickername bghead pt-3 mb-0" style={{height:50,width:150}}>
              <p className=" fw-bold">SPOTLIGHT</p></div>
              <div className="tickercontent bggrey1 w-100">
                <marquee direction="left" scrolldelay="50" scrollamount="5" truespeed="" behavior="scroll" height="25px">
                  <ul className="tickernewsbar text-center">
                    <li className="mx-4 pt-3"><b>ఓం హ్రీం క్లీం శ్రీం శివాయ బ్రహ్మణే నమః</b></li>
                    
                  
                    </ul>
                    </marquee>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>



    <Carousel>
    <Carousel.Item>
        <img src={b18}  />
      </Carousel.Item>
      <Carousel.Item>
        <img src={b2}  />
      </Carousel.Item>
      <Carousel.Item>
        <img src={b17} />
      </Carousel.Item>
      <Carousel.Item>
        <img src={b16} />
      </Carousel.Item>
    </Carousel>
    
    <div className='container pt-2'>
      <div className='row'>
        <div className='col-sm-12'>
          <h4 className='titlecolor'>About</h4>
          <p className='jus'> The Sri Veera Brahmendra Swamy Temple was established by Sri Valaboju Chandra Mouly to create a sacred space for the community. To realize this vision, he purchased land specifically for this purpose, selling approximately 4.5 acres of his family’s property located behind the current temple site. This land was dedicated to developing a temple that would serve all communities. Following the guidelines of the Endowment Department, additional land was donated for further enhancement of the temple.</p>
   <p className='jus'> The temple was officially registered on February 22, 1995, at the Registrar’s Office, with Sri Valaboju Chandra Mouly, son of Valaboju Ramaiah, appointed as the Managing Trustee. A letter dated July 5, 2003, confirmed his role as the managing trustee, ensuring the temple remained under his leadership. </p>
<p className='jus'>The temple is home to several divine deities, including Sri Brahmendra Swamy, Smt. Govindmaba, Bhakta Siddappa, Sri Bhakta Anjaneya Swamy, a Shivalingam, and the Navagrahalu, providing a rich spiritual experience for all who visit.</p>


        </div>
      </div>
      <div className='row'>
        <div className='col-sm-12'>
          <h4 className='titlecolor'>Sri Veerabrahmendra Swamy Temple Trust</h4>
          <div className='row pb-4'>
            <div className='col-sm-6 text-center'>
              <div className='card text-center align-items-center justify-content-center'>
                <img src={img1} className='text-center' style={{width:200}}/>
                <h7 className="fw-bold">Temple Trustee Chairman </h7>
                <p> Late Sri Valaboju Chandra Mouly</p>
              </div>
            </div>
            <div className='col-sm-6 h-100 text-center'>
              <div className='card h-100 text-center align-items-center justify-content-center'>
                <img src={i23} className='text-center' style={{height:225,width:220}}/>
                <h7 className="fw-bold">Temple Trustee  </h7>
                <p> Smt Valaboju Anasuya Devi</p>
              </div>
            </div>
            <div className='col-sm-6 py-2'>
              <div className='card'>
                <img src={img3} className='hhe' />
                <h7 className="fw-bold">Chairman</h7>
                
                <p>  Sri Valaboju Ramachandra Rao Chary</p>
                  <p> Smt Adilakshmi </p>
              </div>
            </div>
            <div className='col-sm-6 py-2'>
              <div className='card'>
                <img  src={img2} className='hhe' />
                <h7 className="fw-bold">Vice Chairman</h7>
                
                <p>  Sri Valaboju Narendra Kumar Chary</p>
                  <p> Smt Aruna Devi </p>
              </div>
            </div>

             <div className='row justify-content-around align-items-around gy-2'>
            <div className='col-sm-4'>
              <div className='card'>
                <h7 className="fw-bold">President</h7>
                <p>Sri Balabhaktula Veera Chary</p>
              </div>
            </div>

            <div className='col-sm-4'>
              <div className='card'>
                <h7 className="fw-bold">Vice President</h7>
                <p>Sri Adduri Bhaskara Chary </p>
              </div>
            </div>

            <div className='col-sm-4'>
              <div className='card'>
                <h7 className="fw-bold">Secretary</h7>
                <p>Sri Devoju Rajendra Chary</p>
              </div>
            </div>

            <div className='col-sm-6 py-2'>
              <div className='card'>
                <h7 className="fw-bold">Assistant Secretary</h7>
                <p>Sri Maaroju Purnima CharyLaxmi</p>
              </div>
            </div>

            <div className='col-sm-6 py-2'>
              <div className='card'>
                <h7 className="fw-bold">Treasurer</h7>
                <p>Sri Kalvacharla Sudhakara Chary </p>
              </div>
            </div>
            </div>


            <div className='col-sm-6 py-2'>
              <div className='card'>
                <h7 className="fw-bold">Advisors</h7>
                <ul>
               <li>Sr Alugoju Veknata Narayan Chary</li>
                   <li>Sri T.V.S. Murthy Chary</li>
                  <li>Smt Sreeramula Sarojini Devi</li>
                <li>Sri Gandloju Bhaskara Charyulu</li>
                <li>Sri Kashivognula Brahma Chary</li>
                <li>Sri Ponugoti Ravindra Chary</li>
                </ul>
              </div>
            </div>
            <div className='col-sm-6 py-2'>
              <div className='card h-100'>
                <h7 className='fw-bold'>Special Advisors</h7>
                <ul>
                <li>Sri Gunti Sanjeeva </li>
                <li>Sri Dodda Ramesh</li>
                <li>Sri Doabani Malliah </li>
                </ul>
              </div>
            </div>
            <div className='col-sm-12 py-2'>
              <div className='card'>
                <h7 className="fw-bold">Pujari</h7>
                <li>Sri Shegnama Rajeshwara Chary</li>
                <li>Sri Velisoju Rajeshwara Chary</li>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    
    </>
  )
}

export default Home