import React from 'react'

function About() {
  return (
    <div>

<div className='container py-3'>
  <div className='row'>
  <div className='col-sm-12'>
     <p className='jus'> The Sri Veera Brahmendra Swamy Temple was established by Sri Valaboju Chandra Mouly to create a sacred space for the community. To realize this vision, he purchased land specifically for this purpose, selling approximately 4.5 acres of his family’s property located behind the current temple site. This land was dedicated to developing a temple that would serve all communities. Following the guidelines of the Endowment Department, additional land was donated for further enhancement of the temple.</p>
   <p className='jus'> The temple was officially registered on February 22, 1995, at the Registrar’s Office, with Sri Valaboju Chandra Mouly, son of Valaboju Ramaiah, appointed as the Managing Trustee. A letter dated July 5, 2003, confirmed his role as the managing trustee, ensuring the temple remained under his leadership. </p>
<p className='jus'>The temple is home to several divine deities, including Sri Brahmendra Swamy, Smt. Govindmaba, Bhakta Siddappa, Sri Bhakta Anjaneya Swamy, a Shivalingam, and the Navagrahalu, providing a rich spiritual experience for all who visit.</p>

<p className='jus'><strong>Founder Trustee responsibilities:</strong></p>
<p className='jus'>1.	After my demise founder trustee is given to my heirs.</p>
<p className='jus'>2.	Executive committee tenure is valid for 2 years or otherwise deemed to be continuing.</p>
<p className='jus'>3.	Any irregularities and misuse of funds by anyone can be terminated from the committee.</p>
<p className='jus'>4.	No one has any right to change the committee.</p>
<p className='jus'>5.	Only Managing Trustee can be dissolve the committee as per the Trust Act 1982.</p>
<p className='jus'><strong>Responsibilities of committee:</strong></p>
<p className='jus'>1.	Appointment of Archaka staff is conducted as per the directions of Trust.</p>
<p className='jus'>2.	Expenses as given by the government to be used for purpose of Dhoopam, Deepa, Naivedyam, Pooja samgri etc.</p>
<p className='jus'>3.	Every donation received by the committee member, a receipt must be given to the donor immediately.</p>


</div>
</div>
</div>

    </div>
  )
}

export default About